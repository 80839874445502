import { IconButton, makeStyles, Modal, ModalProps } from '@material-ui/core'
import _ from 'lodash'
import React from 'react'
import CloseCircleIcon from '../Icon/svg/CloseCircleIcon'
import Line from '../Line'
import theme from '@/theme'

export interface SizeProps {
    size: number | `${number}${'px' | '%'}`
}

const useStyle = makeStyles({
    title: {
        position: 'relative',
        margin: '0 0 10px',
        textAlign: 'center',
        fontSize: 16,
        fontWeight: 400,
        color: theme.colors.black2,
    },
    closeBtn: {
        position: 'absolute',
        top: -20,
        right: -30,
    },
})
interface CustomModalProps extends ModalProps {
    title?: string
    width?: SizeProps['size']
    maxWidth?: SizeProps['size']
    minWidth?: SizeProps['size']
    height?: SizeProps['size']
    maxHeight?: SizeProps['size']
    minHeight?: SizeProps['size']
}

function CustomModal(props: CustomModalProps) {
    const ContentStyles = () => {
        const ContentStyles: React.CSSProperties = {
            padding: '20px 30px',
            background: '#fff',
            borderRadius: '5px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: props.width || 'auto',
            height: props.height || 'auto',
            maxWidth: props.maxWidth || 'auto',
            maxHeight: props.maxHeight || 'auto',
            minWidth: props.minWidth || 'auto',
            minHeight: props.minHeight || 'auto',
        }
        return ContentStyles
    }
    const modalProps = _.omit(props, ['width', 'maxWidth', 'minWidth', 'height', 'maxHeight', 'minHeight', 'title'])
    const classes = useStyle()

    return (
        <Modal {...modalProps} disableScrollLock>
            <div style={ContentStyles()}>
                {props.title && (
                    <h4 className={classes.title}>
                        {props.title}
                        <IconButton className={classes.closeBtn} onClick={props.onClose as any}>
                            <CloseCircleIcon />
                        </IconButton>
                    </h4>
                )}
                <Line />
                {props.children}
            </div>
        </Modal>
    )
}

export default CustomModal
